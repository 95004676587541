<template>
  <client-only>
    <div v-click-away="away">
      <div class="relative z-[100]">
        <div
          class="flex items-center cursor-pointer group"
          :class="[
            scrollPosition >= 80 && isOpen !== true ? 'scrolledHover' : '',
            isProductPage ? 'nav-product' : '',
          ]"
          @click="onHoverOpen"
        >
          <span
            v-if="linkTitle"
            class="uppercase cursor-pointer group-hover:md:!text-primary font-replica-pro nav-link"
            :class="[
              isOpen
                ? '!text-primary'
                : scrollPosition >= 80 || isStorePage
                ? '!text-black '
                : '!text-white ',
              newTextClass,
            ]"
          >
            {{ linkTitle }}
          </span>
          <slot v-else />
          <!-- v-if="!isOpen" -->
          <AngleDown
            class="w-6 h-6 transition-transform duration-150 transform group-hover:md:!text-primary pl-[2px]"
            :class="[
              isOpen
                ? '!text-primary'
                : (scrollPosition >= 80 && !isOpen) || isStorePage
                ? '!text-black'
                : '!text-white',
              isOpen ? 'rotate-180' : 'rotate-0',
            ]"
          />
        </div>
      </div>
      <div v-if="isOpen">
        <div
          class="absolute inset-x-0 bg-white flyout-drawer top-16 lg:top-[88px] z-[999]"
        >
          <div class="h-full md:container xl:pr-[11rem] xl:pl-[18.5rem]">
            <!-- <div
            class="absolute z-50 top-1.5 right-1.5 rtl:right-auto rtl:left-1.5 hover:opacity-60 md:block hidden"
          >
            <button @click="away">
              <CloseIcon class="w-12 h-12 text-black" />
            </button>
          </div> -->
            <!---------------- product list menu --------------------->
            <nav v-if="isProduct" class="flex flex-col h-full md:flex-row">
              <div
                class="menu-height md:px-0 flex flex-row md:min-h-[347px] md:h-full box"
              >
                <!--main nav -->
                <div
                  class="relative z-40 whitespace-nowrap w-2/3 h-full md:w-auto min-w-[180px] lg:min-w-32 xl:min-w-[180px] md:min-h-[352px]"
                >
                  <div
                    class="menu-height h-full !overflow-y-auto no-scrollbar py-[31px]"
                  >
                    <transition-group
                      class="m-0 space-y-[10px]"
                      name="flyout-item-primary"
                      tag="ul"
                      appear
                    >
                      <li
                        v-for="(cat, cIndex) in products"
                        v-show="isOpen"
                        :key="`key-${cIndex}`"
                        class="table w-full !h-[30px]"
                        :style="{ '--i': cIndex }"
                      >
                        <a
                          href="#"
                          class="flex !font-OPPO-sans-heavy items-center gap-3 px-3 text-base font-normal !leading-[26px] md:!leading-[30px] uppercase break-words transition duration-150 ease-in-out cursor-pointer lg:text-lg text-dark-gray hover:text-primary-700 hover:no-underline"
                          :class="{
                            'link-selected rtl:border-l ltr:border-r':
                              state.menuSelected &&
                              state.menuSelected.original_name ===
                                cat.original_name,
                          }"
                          @mouseenter="onChangeMenu(cat)"
                          @touchstart="onChangeMenu(cat)"
                          @touchend="(e) => e.preventDefault()"
                          v-text="cat.name ? cat.name : cat.original_name"
                        />
                      </li>
                    </transition-group>
                  </div>
                  <div class="bottom-overlay"></div>
                </div>

                <!-- secondary nav -->
                <template v-if="state.menuSelected">
                  <div
                    v-for="(products, menuTitle, realIndex) in state
                      .menuSelected.products"
                    :key="`key-${menuTitle}-${state.menuSelected.name.replace(
                      /\s/g,
                      ''
                    )}`"
                    :style="{
                      '--i': realIndex,
                    }"
                    class="menu-height block md:min-h-[352px] z-30 w-3/5 !overflow-visible h-auto relative overflow-y-auto md:whitespace-nowrap no-scrollbar flyout-secondary-nav ltr:pl-5 rtl:pr-5 md:w-auto md:min-w-[192px] rtl:ml-5 ltr:mr-5 md:!mx-0 border-r border-[#f1f1f1] md:border-0"
                  >
                    <div
                      class="h-full overflow-y-auto no-scrollbar py-[34px]"
                      style="max-height: calc(100dvh - 88px)"
                    >
                      <ul class="m-0 space-y-2 scroll-height">
                        <li
                          v-for="(product, prdIndex) in products"
                          :key="`key-${prdIndex}`"
                          class="table w-full leading-6"
                          @click="onClosePopup"
                        >
                          <nuxt-link
                            :to="$i18nPath(`/products/${product.slug}`)"
                            class="w-full inline-block font-normal !leading-6 md:leading-[26px] transition duration-150 ease-in-out font-OPPO-sans-bold text-dark-gray text-base"
                            :class="{
                              'link-selected rtl:border-l ltr:border-r':
                                state.itemSelected
                                  ? state.itemSelected.id == product.id
                                  : prdIndex == 0
                                  ? (state.itemSelected = product)
                                  : false,
                            }"
                            @mouseenter.native="state.itemSelected = product"
                            @touchstart.native="state.itemSelected = product"
                            @touchend.native="(e) => e.preventDefault()"
                            :prefetch="false"
                          >
                            {{ product.name }}
                          </nuxt-link>
                          <!-- v-text="
                            product.name ? product.name : product.original_name
                          " -->
                        </li>
                      </ul>
                    </div>
                    <div class="bottom-overlay"></div>
                  </div>
                </template>
              </div>

              <!-- Product image -->
              <template v-if="state.itemSelected">
                <div
                  class="relative flex-1 px-4 text-center md:pl-2 md:pr-0 xl:px-4 pb-6 md:pb-[30px] flyout-secondary-nav"
                >
                  <div
                    class="flex flex-col justify-start h-full md:max-w-[450px] lg:max-w-full"
                  >
                    <div
                      class="flex flex-1 items-center justify-center md:justify-start lg:justify-center w-full md:max-w-[450px] min-h-[204px] md:max-h-[250px] mt-[15px] mb-7 md:mt-4 md:mb-0"
                    >
                      <ImgLazyLoader
                        v-if="state.itemSelected && state.itemSelected.image"
                        :src="state.itemSelected.image"
                        class="max-h-[204px] md:max-h-[300px] h-full max-w-full flex flex-1 items-center justify-center md:justify-start lg:justify-center"
                        image-class="max-h-[204px] md:max-h-[300px] h-full w-[310px] w-full max-w-full object-contain lg:object-cover object-center md:max-w-[414px] lg:w-[414px] lg:w-auto"
                        loading="lazy"
                      />
                      <div
                        v-else
                        class="text-secondary-light w-[310px] max-h-[204px] lg:max-h-[212px] md:max-w-[414px] lg:max-w-[347px] my-2"
                      >
                        <img
                          src="../assets/images/no-products.png"
                          class="w-full h-auto"
                        />
                      </div>
                    </div>
                    <div
                      class="flex flex-row gap-4 lg:gap-2 lg:pl-3 justify-center items-center min-w-[160px] xl:min-w-[200px] md:mt-7 lg:mt-[34px]"
                    >
                      <Btn
                        @click="
                          onClickLink(
                            $i18nPath(`/products/${state.itemSelected.slug}`)
                          )
                        "
                        type="submit"
                        size="base"
                        rounded
                        class="w-full text-white uppercase md:hidden"
                      >
                        <span
                          class="font-OPPO-sans-bold text-sm !leading-6 tracking-[-0.8px] rounded-[6px]"
                        >
                          {{ $t("common.go_model") }}
                        </span>
                      </Btn>
                      <div class="min-w-[140px]">
                        <div
                          v-if="
                            state.itemSelected.min_price &&
                            parseFloat(state.itemSelected.min_price) > 0
                          "
                          class="flex flex-col md:flex-row items-start md:items-center text-sm leading-6 min-w-[140px]"
                        >
                          <span class="text-dark-gray font-OPPO-sans-regular">
                            {{ $t("common.price_from") }} :
                          </span>
                          <span
                            class="font-OPPO-sans-heavy md:pl-1 text-primary-800"
                            >{{
                              $formatPrice(state.itemSelected.min_price)
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </nav>

            <!----------------- categories list menu ----------------->
            <!-- <nav v-else class="flex flex-row min-h-[500px] max-h-[80vh] py-8 sm:py-12"> -->
            <!--main nav -->
            <!-- <div class="w-56 pr-4 lg:w-60">
              <transition-group
                class="pr-8 space-y-4 xl:space-y-6"
                name="flyout-item-primary"
                tag="ul"
              >
                <li
                  v-for="(cat, cIndex) in categories"
                  v-show="isOpen"
                  :key="`key-${cIndex}`"
                  class="table"
                  :style="{'--i': cIndex}"
                >
                  <a
                    class="flex items-center gap-3 px-3 py-2 -m-3 text-xs font-medium font-semibold tracking-wide uppercase break-words break-all transition duration-150 ease-in-out cursor-pointer lg:text-sm text-dark-gray font-replica-pro hover:text-primary-700 hover:no-underline"
                    :class="{
                      'link-selected':
                        menuSelected &&
                        menuSelected.original_name === cat.original_name
                    }"
                    @mouseenter="
                      menuSelected = cat
                      itemSelected = null
                    "
                    @touchstart="
                      menuSelected = cat
                      itemSelected = null
                    "
                    @touchend="e => e.preventDefault()"
                    v-text=" cat.name"
                  />
                </li>
              </transition-group>
            </div> -->

            <!-- secondary nav -->
            <!-- <template v-if="menuSelected">
              <div class="block w-56 px-5 border-l rtl:border-r rtl:border-l-0 lg:w-64 flyout-secondary-nav-animation">
                <ul class="space-y-2">
                  <li
                    v-for="(category, prdIndex) in menuSelected.children"
                    :key="`key-${prdIndex}`"
                    class="table"
                  >
                    <nuxt-link
                      :to="$i18nPath(`/search/accessories?category=${category.original_name}`)"
                      class="inline w-full text-xs font-light font-medium transition duration-150 ease-in-out text-dark-gray font-replica-pro lg:text-sm"
                      :class="{
                        'link-selected': itemSelected
                          ? itemSelected.id == category.id
                          : false
                      }"
                      @mouseenter.native="itemSelected = category"
                      @touchstart.native="itemSelected = category"
                      @touchend.native="e => e.preventDefault()"
                      v-text="category.name"
                    />
                  </li>
                </ul>
              </div>
            </template> -->

            <!-- Category image -->
            <!-- <template v-if="itemSelected">
              <div class="flex-1 px-6 text-center border-l">
                <div class="max-w-[450px]">
                  <nuxt-link
                    :to="$i18nPath(`/search/equipment?category=${itemSelected.original_name}`)"
                    :prefetch="false"
                    class="flex items-center justify-center"
                    style="min-height: 253px;"
                  >
                    <img
                      v-if="itemSelected && itemSelected.image"
                      :src="itemSelected.image"
                      class="h-auto max-w-full"
                      loading="lazy"
                    >
                    <div
                      v-else
                      class="text-[#d7d7d7] h-40 w-40"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                      </svg>
                    </div>
                  </nuxt-link>
                </div>
              </div>
            </template> -->
            <!-- </nav> -->
          </div>
        </div>
      </div>
    </div>
  </client-only>
</template>

<script setup>
import CloseIcon from "@/assets/svg/heroicons/x-circle.svg?component";
import AngleDown from "@/assets/svg/heroicons/angle-down.svg?component";
import AngleUp from "@/assets/svg/heroicons/angle-up.svg?component";
import { useMainStore } from "@/store/index";

const props = defineProps({
  color: {
    type: String,
    required: false,
    default: "white",
  },
  linkTitle: {
    type: String,
    required: false,
    default: "",
  },
  isProduct: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const store = useMainStore();
const emit = defineEmits();
const router = useRouter();
const nuxtApp = useNuxtApp();

const isOpen = ref(false);
const scrollPosition = ref(0);
const isProductPage = ref(false);
const isStorePage = ref(false);
let state = reactive({
  menuSelected: null,
  itemSelected: null,
});

const $i18nPath = nuxtApp.$i18nPath;
const $formatPrice = nuxtApp.$formatPrice;
const products = store.products;
const categories = store.categories;

const newTextClass = computed(() => {
  if (store.locale === "el" || store.locale === "ar") {
    return "xl:text-xs text-[11px] ";
  } else {
    return "xl:text-sm text-[11px] ";
  }
});

watch(
  router.currentRoute,
  (newValue, oldValue) => {
    away();

    if (newValue.name == "country-products-slug") {
      isProductPage.value = true;
    } else {
      isProductPage.value = false;
    }
    const pageName = nuxtApp.$getKeyByValue(
      store.pageLinks,
      newValue.params.page
    );

    if (pageName == "stores") {
      isStorePage.value = true;
    } else {
      isStorePage.value = false;
    }
  },
  { deep: true }
);

watch(isOpen, (newValue, oldValue) => {
  if (newValue) {
    state.menuSelected = products.length > 0 && products[0];

    state.itemSelected =
      state.menuSelected &&
      Object.keys(state.menuSelected.products).length > 0 &&
      state.menuSelected.products["All"][0];
  }
});

onMounted(() => {
  isProductPage.value = window.location.href.includes("/products/");
  isStorePage.value = window.location.href.includes("/stores");
  window.addEventListener("scroll", updateScroll);
  if (process.browser) {
    heightCheck();
  }
});

onUnmounted(() => {
  window.removeEventListener("scroll", updateScroll);
});

const onClickLink = (route) => {
  isOpen.value = false;
  router.push(route);
};

const onClosePopup = () => {
  isOpen.value = false;
};

const onChangeMenu = (cat) => {
  const timeout = setTimeout(() => {
    const box = document.querySelector(".box");
    // Check height initially
    checkHeight(box);
    clearTimeout(timeout);
  }, 100);
  state.menuSelected = cat;
  state.itemSelected = null;
};
const heightCheck = () => {
  const box = document.querySelector(".box");
  if (!box) return;

  // Check height initially
  checkHeight(box);
};

const checkHeight = (box = null) => {
  if (!box) return false;
  if (!isOpen.value) return false;
  const computedStyle = getComputedStyle(box).maxHeight;
  if (!computedStyle || computedStyle == "none") return false;

  const maxHeight = parseFloat(computedStyle.split("px").join(""));
  if (isNaN(maxHeight)) return false;

  if (maxHeight == NaN || maxHeight == "NaN") {
    return false;
  }
  const boxScroll = document.querySelector(".scroll-height");

  if (!boxScroll) return false;
  if (boxScroll.scrollHeight >= maxHeight) {
    box.classList.add("border-bottom");
  } else {
    box.classList.remove("border-bottom");
  }
};

function away() {
  // document.body.style.overflowY = 'visible'
  isOpen.value = false;
  reset();
}

function onHoverOpen() {
  // document.body.style.overflowY = 'hidden'
  emit("color", "black");
  isOpen.value = !isOpen.value;

  if (!isOpen.value) {
    // document.body.style.overflowY = 'visible'
    reset();
  }
}

function reset() {
  state.menuSelected = null;
  state.itemSelected = null;
  emit("color", "white");
}

function updateScroll() {
  scrollPosition.value = window.scrollY;
}
</script>

<style lang="postcss">
.flyout-drawer {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
  @apply bg-white h-auto;
  /* height: calc(100dvh - 88px); */

  a {
    @apply font-OPPO-sans-regular leading-4 no-underline;

    &.btn {
      @apply font-OPPO-sans-bold text-white not-italic;
    }
    &:hover,
    &.link-selected {
      @apply text-primary-700 opacity-100 !no-underline  border-primary;
    }

    &.btn:hover {
      @apply text-white;
    }
  }
}
.box.border-bottom {
  @apply border-b border-[#E7E7E7];
}

/* .scrollable-header .flyout-drawer {
  box-shadow: 0px 2px 2px 0px rgba(151, 151, 153, 0.12) !important;
} */
.bottom-overlay {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) -24.59%,
    #fff 121.31%
  );
  @apply absolute bottom-0 inset-x-1 h-2 md:h-[41px];
}

.flyout-drawer {
  .scrollbar-thumb::-webkit-scrollbar-track {
    @apply !m-0;
  }
  .scrollbar-thumb::-webkit-scrollbar {
    @apply !w-[1px];
    position: absolute;
    left: 100%;
  }
  .scrollbar-thumb::-webkit-scrollbar-thumb {
    @apply !bg-primary !h-6;
  }
}

.flyout-drawer::before {
  @apply relative inset-0 w-full h-full;

  content: "";
  animation-name: flyout-drawer-slide-down;
  animation-duration: 0.75s;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes flyout-drawer-slide-down {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.flyout-item-primary-enter-active,
.flyout-item-primary-leave-active {
  animation-name: flyout-item-primary-animation;
  animation-delay: calc(0.15s * var(--i));
  animation-duration: 0.15s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  opacity: 0;
  transform: translateY(66%);
}

.flyout-secondary-nav-animation::after {
  content: "";
  @apply absolute right-full w-[1px] bg-[#E7E7E7] top-0 h-full;
}
.flyout-secondary-nav::after {
  content: "";
  @apply absolute rtl:left-full ltr:right-full w-[1px] bg-[#E7E7E7] top-0 h-full;
}

.flyout-secondary-nav a {
  @apply pr-2 md:pr-4;
}

@keyframes flyout-item-primary-animation {
  from {
    opacity: 0;
    transform: translateY(66%);
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes flyout-secondary-nav-animation {
  0% {
    transform: translateX(-30px);
    opacity: 0;
  }

  67% {
    transform: translateX(0);
    opacity: 0.67;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.flyout-secondary-nav-animation {
  animation-name: flyout-secondary-nav-animation;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-delay: calc(0.25s * var(--i));
}
@media (max-width: 767px) {
  .flyout-drawer {
    height: auto;
    max-height: calc(100dvh - 73px);
  }
  .menu-height {
    max-height: calc(100dvh - 385px);
  }
}
</style>
