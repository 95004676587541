import { useMainStore } from "~/store";

export default defineNuxtPlugin((nuxtApp) => {

    const ctm = setTimeout(() => {
        callbackAfterSomeTime(nuxtApp);
        clearTimeout(ctm);
    }, 6000);
});

function callbackAfterSomeTime() {
    const store = useMainStore();
    /*
     ** Only run on client-side and only in production mode
     */
    if (process.env.NODE_ENV !== 'production' || !store.currentSite || !store.currentSite.settings) { return false }
    /*
     ** Initialize Facebook Pixel Script
     */
    // eslint-disable-next-line no-unused-expressions
    if (import.meta.browser) {
        !(function (f, b, e, v, n, t, s) {
            if (f.fbq) {
                return;
            }
            n = f.fbq = function () {
                n.callMethod
                    ? n.callMethod.apply(n, arguments)
                    : n.queue.push(arguments);
            };
            if (!f._fbq) {
                f._fbq = n;
            }
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
        );
        fbq("init", store.currentSite.settings.facebook_pixel_code);
        fbq("track", "PageView");
    }
}
