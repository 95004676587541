export default defineNuxtPlugin(async (nitroApp) => {
  if (import.meta.server) {
    try {
      const apiUrl = `${process.env.API_URL}api/robot-txt`;
      const response = await $fetch(apiUrl);

      const seoSetting = response

      if (seoSetting) {
        let rule = []
        if (process.env.NODE_ENV == "production") {
          rule = [
            'max-image-preview:large', 'max-snippet:-1', 'max-video-preview:-1'
          ]
          if (seoSetting) {
            if (seoSetting.index == 'true') {
              rule.push('index')
            } else {
              rule.push('noindex')
            }
            if (seoSetting.follow == 'true') {
              rule.push('follow')
            } else {
              rule.push('nofollow')
            }
          } else {
            rule.push('index')
            rule.push('follow')
          }
        } else {
          rule = ['noindex', 'nofollow']
        }
        console.log(rule)

        useServerSeoMeta({
          robots: rule.join(', ')
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
})