import { default as _91page_93beDSl9Je7KMeta } from "/usr/src/app/pages/[country]/[page].vue?macro=true";
import { default as indexiqpEXIlIlCMeta } from "/usr/src/app/pages/[country]/checkout/_code/index.vue?macro=true";
import { default as resultkWgns7kX4dMeta } from "/usr/src/app/pages/[country]/checkout/_code/result.vue?macro=true";
import { default as indexiC2XA9kvitMeta } from "/usr/src/app/pages/[country]/index.vue?macro=true";
import { default as index1eQN3vFTuyMeta } from "/usr/src/app/pages/[country]/my-account/addresses/index.vue?macro=true";
import { default as indexjOLwuCpDGTMeta } from "/usr/src/app/pages/[country]/my-account/bikes/index.vue?macro=true";
import { default as indexC7On3iwKIYMeta } from "/usr/src/app/pages/[country]/my-account/index.vue?macro=true";
import { default as indexyod0bKbstNMeta } from "/usr/src/app/pages/[country]/my-account/orders/[code]/index.vue?macro=true";
import { default as indexovZMFqsrpwMeta } from "/usr/src/app/pages/[country]/my-account/orders/index.vue?macro=true";
import { default as _91slug_id_93rTeuqZVpP4Meta } from "/usr/src/app/pages/[country]/news/[slug_id].vue?macro=true";
import { default as _91slug_930u3Pwi1NwQMeta } from "/usr/src/app/pages/[country]/products/[slug].vue?macro=true";
import { default as _91type_93w52rTHu4oXMeta } from "/usr/src/app/pages/[country]/search/[type].vue?macro=true";
import { default as _91token_93btSrYlR05NMeta } from "/usr/src/app/pages/[country]/update-password/[token].vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
export default [
  {
    name: "country-page",
    path: "/:country()/:page()",
    component: () => import("/usr/src/app/pages/[country]/[page].vue")
  },
  {
    name: "country-checkout-_code",
    path: "/:country()/checkout/_code",
    meta: indexiqpEXIlIlCMeta || {},
    component: () => import("/usr/src/app/pages/[country]/checkout/_code/index.vue")
  },
  {
    name: "country-checkout-_code-result",
    path: "/:country()/checkout/_code/result",
    component: () => import("/usr/src/app/pages/[country]/checkout/_code/result.vue")
  },
  {
    name: "country",
    path: "/:country()",
    component: () => import("/usr/src/app/pages/[country]/index.vue")
  },
  {
    name: "country-my-account-addresses",
    path: "/:country()/my-account/addresses",
    meta: index1eQN3vFTuyMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/addresses/index.vue")
  },
  {
    name: "country-my-account-bikes",
    path: "/:country()/my-account/bikes",
    meta: indexjOLwuCpDGTMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/bikes/index.vue")
  },
  {
    name: "country-my-account",
    path: "/:country()/my-account",
    meta: indexC7On3iwKIYMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/index.vue")
  },
  {
    name: "country-my-account-orders-code",
    path: "/:country()/my-account/orders/:code()",
    meta: indexyod0bKbstNMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/orders/[code]/index.vue")
  },
  {
    name: "country-my-account-orders",
    path: "/:country()/my-account/orders",
    meta: indexovZMFqsrpwMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/orders/index.vue")
  },
  {
    name: "country-news-slug_id",
    path: "/:country()/news/:slug_id()",
    component: () => import("/usr/src/app/pages/[country]/news/[slug_id].vue")
  },
  {
    name: "country-products-slug",
    path: "/:country()/products/:slug()",
    component: () => import("/usr/src/app/pages/[country]/products/[slug].vue")
  },
  {
    name: "country-search-type",
    path: "/:country()/search/:type()",
    component: () => import("/usr/src/app/pages/[country]/search/[type].vue")
  },
  {
    name: "country-update-password-token",
    path: "/:country()/update-password/:token()",
    meta: _91token_93btSrYlR05NMeta || {},
    component: () => import("/usr/src/app/pages/[country]/update-password/[token].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue")
  }
]