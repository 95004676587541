<template>
  <section
    id="newsletter"
    class="flex flex-col items-center justify-center py-12 bg-white lg:py-[66px] px-4"
  >
    <h2
      class="inline mb-4 text-[20px] uppercase md:text-[28px] md:leading-[32px] font-normal leading-[28px] tracking-tight text-center text-black font-OPPO-sans-heavy sm:block"
    >
      {{ $t("common.newsletter_title") }}
    </h2>
    <form
      id="newsletter_form"
      class="flex-col items-center justify-center block w-full py-4 md:flex"
      @submit.prevent="onSubmit"
    >
      <div class="md:max-w-[640px] flex md:items-center flex-col">
        <div class="flex items-center justify-center">
          <input
            id="newsletter_email"
            v-model="email"
            name="email"
            type="email"
            data-cons-subject="email"
            autocomplete="email"
            required
            :disabled="iSsending"
            class="w-full md:w-[560px] font-OPPO-sans-heavy focus-within:z-10 focus:ring-primary-500 focus:border-primary-500 md:rounded-s-[10px] rounded-[10px] md:rounded-e-none text-black placeholder:text-black text-[13px] font-medium border-[#F6F6F6] h-[52px] bg-[#F6F6F6] py-3"
            :placeholder="$t('common.newsletter_subscribe')"
          />
          <Btn
            id="newsletter-submit"
            type="submit"
            :disabled="iSsending"
            size="sm"
            color="primary"
            class="h-[52px] md:block hidden rounded-s-none tracking-tighter text-sm uppercase"
          >
            {{ $t("common.submit") }}
          </Btn>
        </div>

        <ErrorBag :errors="errorsObj" class="mt-4" />

        <div v-if="VueHcaptcha" class="bg-gray-50">
          <component
            :is="VueHcaptcha"
            ref="invisibleHcaptcha"
            size="invisible"
            :sitekey="$config.HCAPTCHA_SITE_KEY"
            theme="dark"
            :language="store.locale"
            @verify="onVerify"
            @expired="onExpire"
            @closed="onExpire"
            @challengeExpired="onExpire"
            @error="onError"
          />
        </div>

        <label
          class="inline-block w-auto py-2 mt-3 text-left border border-transparent cursor-pointer select-none text-primary-500 newsletter__policies--checkbox"
          :class="{
            'border-red-500 bg-red-50 text-red-400':
              errorsObj && errorsObj.accept_terms,
          }"
        >
          <input
            id="newsletter_tos"
            v-model="accept_terms"
            type="checkbox"
            required
            data-cons-preference="terms-and-conditions"
            class="me-3 cursor-pointer relative bottom-[.1rem] text-primary-600 bg-[#F6F6F6] border-[#F6F6F6] rounded focus:ring-primary-600 focus:ring-2"
          />
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="text-sm font-normal text-left text-[#000] font-OPPO-sans-regular leading-[24px] terms newsletter__checkmark"
            v-html="
              $t('common.i_agree_with_the_terms_and_conditions').replace(
                'legal',
                $i18nPath(store.pageLinks['terms'])
              )
            "
          />
          <!-- eslint-enable -->
        </label>
      </div>
      <input
        type="hidden"
        name="newsletter"
        value="newsletter"
        data-cons-preference="newsletter"
      />

      <Btn
        id="newsletter-submit"
        type="submit"
        :disabled="iSsending"
        size="sm"
        color="primary"
        class="h-[50px] md:hidden mt-5 block w-full tracking-tighter text-sm uppercase"
      >
        <span class="text-black font-replica-bold"
          >{{ $t("common.submit") }}
        </span>
      </Btn>
    </form>
  </section>
</template>

<script setup>
// import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";/
import { useMainStore } from "@/store/index";
import { useTranslation } from "i18next-vue";

const { t } = useTranslation();

const nuxtApp = useNuxtApp();
const store = useMainStore();

let VueHcaptcha = ref(null);
const email = ref(null);
const accept_terms = ref(false);
const iSsending = ref(false);
const invisibleHcaptcha = ref(null);
let errorsObj = reactive({});
const $i18nPath = nuxtApp.$i18nPath;
const $config = nuxtApp.$config.public;

onMounted(() => {
  if (process.browser) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!VueHcaptcha.value) {
            VueHcaptcha.value = defineAsyncComponent(() =>
              import("@hcaptcha/vue3-hcaptcha")
            );
          }
        }
      });
    });

    observer.observe(document.getElementById("newsletter"));
  }
});

function onVerify(token, ekey) {
  useAPI("/api/front/subscriber", {
    method: "POST",
    body: {
      email: email.value,
      accept_terms: accept_terms.value,
      "h-captcha-response": token,
    },
  })
    .then((response) => {
      nuxtApp.$toast.success(t("common.newsletter_thanks"));
      email.value = null;
      accept_terms.value = false;
      iSsending.value = false;

      window._iub.cons_instructions.push([
        "submit",
        {
          writeOnLocalStorage: false,
          form: {
            selector: document.getElementById("newsletter_form"),
          },
          consent: {
            legal_notices: [
              {
                identifier: "privacy_policy",
              },
              {
                identifier: "cookie_policy",
              },
              {
                identifier: "term",
              },
            ],
          },
        },
        {
          success(response) {
            // console.log(response)
          },
          error(response) {
            console.warning(response);
          },
        },
      ]);
    })
    .catch((error) => {
      console.error(error);
      errorsObj = error.response.data.errors;
      iSsending.value = false;
      accept_terms.value = false;
    });
}

function onExpire() {
  // this.$toast.error('Captcha expired')
  console.log("[vue-htcapcha] Expired token");
  iSsending.value = false;
}

function onError() {
  console.log("[vue-htcapcha] Failed to load hcaptcha");
  iSsending.value = false;
}

function onSubmit() {
  if (iSsending.value) {
    return;
  }
  iSsending.value = true;
  errorsObj = {};

  if (invisibleHcaptcha.value) {
    invisibleHcaptcha.value.execute();
  }
}
</script>

<style lang="postcss">
#newsletter_form .terms a {
  @apply underline !text-[#000] font-OPPO-sans-regular;
  &:hover {
    @apply !text-primary;
  }
}
.newsletter__policies--checkbox {
  .newsletter__checkmark {
    height: 17px;
    width: 17px;
    top: 0.2rem;
    left: 0;
    border-radius: 2px;

    &::after {
      content: "";
      @apply absolute hidden;
      top: 3px;
      left: 6px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
</style>
